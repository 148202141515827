import React from 'react';
import PropTypes from 'prop-types';
import SC from './styles';

const BenefitItem = props => {
  return (
    <SC.BenefitItem>
      {props.img.src && (
        <SC.ImageContainer>
          <SC.Image src={props.img.src} alt={props.img.alt} />
        </SC.ImageContainer>
      )}
      <SC.Container>
        <SC.Heading theme={props.theme}>{props.title}</SC.Heading>
        {props.text && <SC.Text theme={props.theme}>{props.text}</SC.Text>}
      </SC.Container>
    </SC.BenefitItem>
  );
};

BenefitItem.defaultProps = {
  theme: 'light',
  img: {
    src: '',
    alt: ''
  }
};

BenefitItem.propTypes = {
  /** Theme determines the color of the font.  Expected values: light, dark */
  theme: PropTypes.string,
  /** Corresponding icon of the benefit.*/
  img: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  /** Title of the benefit */
  title: PropTypes.string,
  /** Description of the benefit */
  text: PropTypes.string
};

BenefitItem.legacyName = ['ux-benefit-component'];

export default BenefitItem;
