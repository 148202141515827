const widgetManager = require('@lux/core').widgetManager.default;
const availableComponents = require('./components').default;

require('@lux/components/build/lux-components.css');

/**
 * Feature Flag enabled components
 *
 * To speed up migration from legacy LUX, we can look for a
 * window.lux2FeatureFlags and if it exists, we use that to
 * filter which components we want to transform from legacy
 * This allows us to turn on and off components in production
 */
let filteredComponents;
if (window.lux2FeatureFlags && window.lux2FeatureFlags.availableComponents) {
  filteredComponents = Object.keys(availableComponents)
    .filter(c => window.lux2FeatureFlags.availableComponents.indexOf(c) !== -1)
    .reduce((accum, key) => {
      accum[key] = availableComponents[key];
      return accum;
    }, {});
}

/**
 * We have found LUX1 on the page, need to check if any LUX2 got affected
 * by any rerenders of parent LUX1 components. If we do have an infected
 * component we will need to re-render it. Not pretty but this is only
 * done on a per component basis if it's found within a LUX1 component.
 */
const checkForLegacyLux = function() {
  // this is a LUX1 global variable - it lets us know if LUX1 exists
  if (window && window.patternLibraryBaseUrl) {
    // window.jQuery exists on a LUX1 page
    window.jQuery(document).on('allWidgetsInitialized.lux', () => {
      const rw = window.RactiveWidgets.map(rw => rw.instance.ractive.el).filter(
        el => el.querySelector('.lux-component-container')
      );
      widgetManager.processWidgets(options, rw);
    });
  }
};

/**
 * WidgetManager requires to have some basic config to initalise
 * Refer to API documentation for full list of available options
 */
const options = {
  availableComponents: filteredComponents || availableComponents,
  appName: __APP_NAME__,
  appVersion: __APP_VERSION__,
  ga: __GA_TRACKER__
};

widgetManager.renderFromMarkup(options).then(checkForLegacyLux);
