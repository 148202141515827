import styled from 'styled-components';
import { Theme } from '@lux/components';
import { remCalc } from '@lux/helpers';

export default {
  BenefitItem: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
  Container: styled.div`
    align-self: flex-start;
  `,
  ImageContainer: styled.div`
    align-self: flex-start;
    flex-basis: ${remCalc(35)};
    flex-grow: 0;
    flex-shrink: 0;
  `,
  Heading: styled.h6`
    ${Theme.fonts.styles.Header6};
    letter-spacing: ${remCalc(0.27)};
    margin-bottom: 0;
    margin-top: 0;
    color: ${props =>
      props.theme === 'dark' ? Theme.colours.white : Theme.colours.black};
  `,
  Image: styled.img`
    max-width: ${remCalc(25)};
    margin-right: ${Theme.spacing.xsmall};
  `,
  Text: styled.p`
    color: ${props =>
      props.theme === 'dark' ? Theme.colours.white : Theme.colours.black};
    margin-top: 0;
    ${Theme.fonts.styles.BodyCopy};
  `
};
