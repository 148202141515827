import React from 'react';
import Loadable from 'react-loadable';

import BenefitItem from './components/BenefitItem';
import MarketingBanner from './components/MarketingBanner';

import { Footer, CrossLinkHorizontal } from '@lux/components';

function Loading() {
  return <div />;
}

const PickAndGoWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "PickAndGoWidget" */ './components/PickAndGoWidget'),
  loading: Loading
});

const PromotionsWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "PromotionsWidget" */ './components/PromotionsWidget'),
  loading: Loading
});

const MobileInternetFilter = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MobileInternetFilter" */ './components/MobileInternetFilter'),
  loading: Loading
});

const HomeInternetFilter = Loadable({
  loader: () =>
    import(/* webpackChunkName: "HomeInternetFilter" */ './components/HomeInternetFilter'),
  loading: Loading
});

const SOGGiftCardDetails = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SOGGiftCardDetails" */ './components/SOGGiftCardDetails'),
  loading: Loading
});

const SOGRecommendations = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SOGRecommendations" */ './components/SOGRecommendations'),
  loading: Loading
});

const SOGCardCatalogue = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SOGCardCatalogue" */ './components/SOGCardCatalogue'),
  loading: Loading
});

const GERoiForm = Loadable({
  loader: () =>
    import(/* webpackChunkName: "GERoiForm" */ './components/GERoiForm'),
  loading: Loading
});

const SAPDealsWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SAPDealsWidget" */ './components/SAPDealsWidget'),
  loading: Loading
});

const GECheckUsageWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "GECheckUsageWidget" */ './components/GECheckUsageWidget'),
  loading: Loading
});

const StoreLocator = Loadable({
  loader: () =>
    import(/* webpackChunkName: "StoreLocator" */ './components/StoreLocator'),
  loading: Loading
});

const ProductCardCatalogue = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ProductCardCatalogue" */ './components/InstantPay/InstantPayProductCardCatalogue'),
  loading: Loading
});

const ProductDetailsView = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ProductDetailsView" */ './components/ProductDetailsView'),
  loading: Loading
});

const SpotifyBuyflowWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SpotifyBuyflowWidget" */ './components/SpotifyBuyflowWidget'),
  loading: Loading
});

const EshopLeasingCatalogue = Loadable({
  loader: () =>
    import(/* webpackChunkName: "EshopLeasingCatalogue" */ './components/EshopLeasingCatalogue'),
  loading: Loading
});

const EshopLeasingDetailsView = Loadable({
  loader: () =>
    import(/* webpackChunkName: "EshopLeasingDetailsView" */ './components/EshopLeasingDetailsView'),
  loading: Loading
});

const PlanSelectionView = Loadable({
  loader: () =>
    import(/* webpackChunkName: "PlanSelectionView" */ './components/PlanSelectionView'),
  loading: Loading
});

const TransactionResult = Loadable({
  loader: () =>
    import(/* webpackChunkName: "TransactionResult" */ './components/TransactionResult'),
  loading: Loading
});

const InstantPay = Loadable({
  loader: () =>
    import(/* webpackChunkName: "InstantPay" */ './components/InstantPay'),
  loading: Loading
});

const DataRoamFlow = Loadable({
  loader: () =>
    import(/* webpackChunkName: "DataRoamFlow" */ './components/DataRoamFlow'),
  loading: Loading
});

const CaseManagementWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "CaseManagementWidget" */ './components/CaseManagementWidget'),
  loading: Loading
});

const BuyFlowWidget = Loadable({
  loader: () => import(/* webpackChunkName: "BuyFlow" */ './widgets/BuyFlow'),
  loading: Loading
});

const MessageWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MessageWidgetFlow" */ './widgets/MessageWidgetFlow'),
  loading: Loading
});

const RedemptionFlow = Loadable({
  loader: () =>
    import(/* webpackChunkName: "RedemptionFlow" */ './widgets/RedemptionFlow'),
  loading: Loading
});

const VasMessageWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "VasMessageWidgetFlow" */ './widgets/VasMessageWidgetFlow'),
  loading: Loading
});

const TradeInWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "TradeInWidget" */ './widgets/TradeInWidget'),
  loading: Loading
});

const PricePlanCards = Loadable({
  loader: () =>
    import(/* webpackChunkName: "PricePlanCards" */ './widgets/PricePlanCards'),
  loading: Loading
});

const CalculatorWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "CalculatorWidget" */ './widgets/CalculatorWidget'),
  loading: Loading
});

const SubscriptionPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SubscriptionPage" */ './components/SubscriptionPage'),
  loading: Loading
});

const SubscriptionFlow = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SubscriptionFlow" */ './widgets/SubscriptionFlow'),
  loading: Loading
});

const ProductListingCategory = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ProductListingCategory" */ './components/ProductListingCategory'),
  loading: Loading
});

const FaqComponent = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FaqComponent" */ './components/FaqComponent'),
  loading: Loading
});

const AtomicMessageWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AtomicMessageWidget" */ './widgets/MessageWidgetFlow'),
  loading: Loading
});

const OtpWidget = Loadable({
  loader: () =>
    import(/* webpackChunkName: "OtpWidget" */ './widgets/OtpWidget'),
  loading: Loading
});

const ActivationFlow = Loadable({
  loader: () => import(/* webpackChunkName: "ActivationFlow" */ './widgets/ActivationFlow'),
  loading: Loading
});

export default {
  SpotifyBuyflowWidget: SpotifyBuyflowWidget,
  BenefitComponent: BenefitItem,
  GERoiForm: GERoiForm,
  FooterLinks: Footer,
  FooterSingtel: Footer,
  HomeInternetFilter: HomeInternetFilter,
  MarketingBanner: MarketingBanner,
  MobileInternetFilter: MobileInternetFilter,
  PickAndGoWidget: PickAndGoWidget,
  PromotionsWidget: PromotionsWidget,
  SAPDealsWidget: SAPDealsWidget,
  SOGCardCatalogue: SOGCardCatalogue,
  SOGGiftCardDetails: SOGGiftCardDetails,
  SOGRecommendations: SOGRecommendations,
  ToutSingtel: CrossLinkHorizontal,
  StoreLocator: StoreLocator,
  GECheckUsageWidget: GECheckUsageWidget,
  ProductCardCatalogue: ProductCardCatalogue,
  ProductDetailsView: ProductDetailsView,
  EshopLeasingCatalogue: EshopLeasingCatalogue,
  EshopLeasingDetailsView: EshopLeasingDetailsView,
  PlanSelectionView: PlanSelectionView,
  TransactionResult: TransactionResult,
  InstantPay: InstantPay,
  DataRoamFlow: DataRoamFlow,
  CaseManagementWidget: CaseManagementWidget,
  MessageWidget: MessageWidget,
  BuyFlowWidget: BuyFlowWidget,
  RedemptionFlow: RedemptionFlow,
  VasMessageWidget: VasMessageWidget,
  TradeInWidget: TradeInWidget,
  PricePlanCards: PricePlanCards,
  CalculatorWidget: CalculatorWidget,
  SubscriptionPage: SubscriptionPage,
  SubscriptionFlow: SubscriptionFlow,
  ProductListingCategory: ProductListingCategory,
  FaqComponent: FaqComponent,
  AtomicMessageWidget: AtomicMessageWidget,
  OtpWidget: OtpWidget,
  ActivationFlow: ActivationFlow
};
