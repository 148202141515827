import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Column, Anchor } from '@lux/components';
import htmlStringToReactComponent from '@lux/components/src/helpers/htmlStringToReactComponent';
import SC from './styles';

const MarketingBanner = props => {
  return (
    <div>
      <Anchor href={props.link}>
        <SC.Background
          showCornerAndShadow={props.showCornerAndShadow}
          theme={props.theme}
          style={{ backgroundImage: `url(${props.backgroundImg})` }}
        >
          <SC.Content>
            <SC.Title>{props.title}</SC.Title>
            <SC.Description theme={props.theme}>{props.desc}</SC.Description>
          </SC.Content>
        </SC.Background>
      </Anchor>
      {props.captionCopy && (
        <SC.CaptionCopy
          showCornerAndShadow={props.showCornerAndShadow}
          theme={props.theme}
          style={{ backgroundColor: props.bgColor }}
        >
          <Grid>
            <Row>
              <Column>
                <SC.CaptionCopyContent>
                  {htmlStringToReactComponent(props.captionCopy)}
                </SC.CaptionCopyContent>
              </Column>
            </Row>
          </Grid>
        </SC.CaptionCopy>
      )}
    </div>
  );
};

MarketingBanner.propTypes = {
  /** Background image path */
  backgroundImg: PropTypes.string,
  /** Banner title */
  title: PropTypes.string,
  /** Banner description */
  desc: PropTypes.string,
  /** Banner link */
  link: PropTypes.string,
  /** Enable rounded corners and shadow */
  showCornerAndShadow: PropTypes.bool,
  /** Caption copy */
  captionCopy: PropTypes.string,
  /** Caption background colour */
  bgColor: PropTypes.string,
  /** Theme */
  theme: PropTypes.oneOf(['light', 'dark'])
};

MarketingBanner.legacyName = 'ux-marketing-banner';

export default MarketingBanner;
